import styled from 'styled-components'

export const Divider = styled.div`
    border-bottom: 1px dashed #e5e5e5;
    width: 100%;
    height: 0;
    padding: 16px 0;
`

export default Divider
