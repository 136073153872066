import React from 'react'
import { graphql } from 'gatsby'
import Header from '../../molecules/Header'
import HowItWorksCmp from '../../organisms/HowItWorks'
import RenewIsHard from '../../organisms/RenewIsHard'
import CollectionCarousel from '../../organisms/CollectionCarousel'
import Footer from '../../organisms/Footer'
import FrequentQuestions from '../../organisms/FrequentQuestions'
import Introduction from '../../organisms/HowItWorks/Introduction'
import WelcomeBetterExp from '../../organisms/WelcomeBetterExp'

export type HowItWorksProps = {}

export const HowItWorks = React.memo<HowItWorksProps>(({}) => {
    return (
        <main>
            <Header />
            <Introduction />
            <WelcomeBetterExp />
            <HowItWorksCmp hideTitle bgColor={'white'} />
            <RenewIsHard bgColor={'flesh'} />
            <FrequentQuestions />
            <CollectionCarousel />
            <Footer />
        </main>
    )
})

HowItWorks.displayName = 'HowItWorks'

export default HowItWorks

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
