import React from 'react'
import SectionTitle from '../../molecules/SectionTitle'
import * as SCW from '../CountWithUs/styled'
import * as S from './styled'
import CollapsedInfo from '../../molecules/CollapsedInfo'
import { SpaceV100 } from '../HowItWorks/styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const q = (title: string, content: string) => ({ title, content })

const questionsKeys = [
    q('how-much-construction-work.title', 'how-much-construction-work.content'),
    q('why-is-it-expensive.title', 'why-is-it-expensive.content'),
    q('what-happens-at-lower-price.title', 'what-happens-at-lower-price.content'),
    q('can-contractor-work-with-us.title', 'can-contractor-work-with-us.content'),
    q(
        'are-contractors-insured-and-graduated.title',
        'are-contractors-insured-and-graduated.content',
    ),
    q('who-do-i-call-if-problems.title', 'who-do-i-call-if-problems.content'),
    q(
        'how-are-materials-ordered-and-transported.title',
        'how-are-materials-ordered-and-transported.content',
    ),
    q(
        'i-have-a-designer-can-i-work-with-us.title',
        'i-have-a-designer-can-i-work-with-us.content',
    ),
    q('can-i-invest-only-on-design.title', 'can-i-invest-only-on-design.content'),
]

export type FrequentQuestionsProps = {}

export const FrequentQuestions = React.memo<FrequentQuestionsProps>(({}) => {
    const { t } = useTranslation(['how-it-works'])
    return (
        <React.Fragment>
            <SCW.SBg color={'white'} useHPadding={true}>
                <SCW.SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                    <SectionTitle title={t('frequent-questions')} />
                    <SpaceV100 />
                    <S.QuestionsWrapper>
                        <S.Questions>
                            {questionsKeys.map(({ title, content }, k) => (
                                <CollapsedInfo key={k} title={t(title)}>
                                    {t(content)}
                                </CollapsedInfo>
                            ))}
                        </S.Questions>
                    </S.QuestionsWrapper>
                </SCW.SContent>
            </SCW.SBg>
        </React.Fragment>
    )
})

FrequentQuestions.displayName = 'FrequentQuestions'

export default FrequentQuestions
