import React from 'react'
import IntroAnimatedImage from '../../../molecules/IntroAnimatedImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import EstimateButton from '../../EstimateButton'
import { EstimateButtonWrapper } from '../../Introduction/styled'
import { useRenderBreakpoints } from '../../../utils/hooks/render'
import { IntroductionImg } from './styled'
import { SpaceV100 } from '../styled'

export type IntroductionProps = {}

export const Introduction = React.memo<IntroductionProps>(({}) => {
    const { t } = useTranslation(['index', 'common', 'how-it-works'])
    const { onTablet } = useRenderBreakpoints()

    return (
        <IntroAnimatedImage
            direction={'lr'}
            bgColor={'flesh'}
            title={t('common:howItWorks')}
            description={t('how-it-works:intro.description')}
            img={<IntroductionImg />}
            beforeTitle={onTablet(
                () => (
                    <SpaceV100 />
                ),
                { mode: 'hide' },
            )}
            afterDescription={
                <EstimateButtonWrapper>
                    {onTablet(
                        ({ matches }) => (
                            <EstimateButton size={matches ? 'normal' : undefined} />
                        ),
                        { mode: 'handle-both' },
                    )}
                </EstimateButtonWrapper>
            }
        />
    )
})

Introduction.displayName = 'Introduction'

export default Introduction
