import styled from 'styled-components'
import { FCol, FRow } from '../../atoms/Layout'

export const QuestionsWrapper = styled(FRow)`
    justify-content: center;
`
export const Questions = styled(FCol)`
    max-width: 700px;
    flex: 1;
`
