import React from 'react'
import Divider from '../../atoms/Divider'
import { Bg } from '../../atoms/Bg'
import Content from '../../atoms/Content'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import SectionTitle from '../../molecules/SectionTitle'
import { FRow } from '../../atoms/Layout'

export type WelcomeBetterExpProps = {}

export const WelcomeBetterExp = React.memo<WelcomeBetterExpProps>(({}) => {
    const { t } = useTranslation(['how-it-works'])
    return (
        <Bg color={'white'} useHPadding={true}>
            <Content variant={'transparent'} useMaxWidth={true} expanded={true}>
                <FRow style={{ justifyContent: 'center' }}>
                    <div style={{ maxWidth: 600, marginTop: 100, marginBottom: 64 }}>
                        <SectionTitle title={t('welcome-better-exp')} />
                    </div>
                </FRow>

                <Divider />
            </Content>
        </Bg>
    )
})

WelcomeBetterExp.displayName = 'WelcomeBetterExp'

export default WelcomeBetterExp
